import React from 'react';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';

const Basicdetails = (props) => {
    const {data} = props
    const activeWorkAuth = data?.workauth?.find((item) => {
        return item?.active === 'Y'
    })
    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-2 header-title">Basic Details</h5>
                <Row>
                    <Col xl={6} className="d-flex border-end">
                        <ListGroup as="ul">
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                Phone :
                                </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                    Maritial status :
                                </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                Email :
                                </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                Mailing Address :
                                </div>
                        </ListGroup.Item>
                        </ListGroup>
                        <ListGroup as="ul">
                            <ListGroup.Item as="li" className='d-flex border-0 px-0 justify-content-between'>
                               
                                <div className="me-auto fw-bold">{data?.personal?.phonenumber ?? '-'}</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pe-xl-2">
                               
                                <div className="me-auto fw-bold">{data?.personal?.maritalstatus ?? '-'}</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto fw-bold">{data?.personal?.emailid ?? '-'}</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pe-xl-2">
                            
                            <div className="me-auto fw-bold text-wrap">                                   
                                {[data?.mailingaddress?.line1, data?.mailingaddress?.line2, data?.mailingaddress?.city, data?.mailingaddress?.state, data?.mailingaddress?.country].filter((i) => i).join(", ")}
                            </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xl={6} className="d-flex">
                    <ListGroup as="ul">
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                Branch :
                                </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                Payroll ID :
                                </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                               Salary :
                                </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto">
                                VISA : 
                                </div>
                        </ListGroup.Item>
                        </ListGroup>
                        <ListGroup as="ul">
                            <ListGroup.Item as="li" className='d-flex border-0 px-0 justify-content-between'>
                               
                                <div className="me-auto fw-bold">{data.personal.branch ?? '-'}</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto fw-bold">{data.personal.payrollid ?? '-'}</div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto fw-bold"> 
                                    $ {data.personal.salary ?? '-'}                                  
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pe-xl-2">
                                <div className="me-auto fw-bold"> 
                                {activeWorkAuth?.work_type ?? '-'}                                  
                                   </div>
                            </ListGroup.Item>
                            
                           
                        </ListGroup>
                        {/* <ListGroup as="ul">
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 ps-xl-2">
                                <div className="me-auto">
                                    Branch :
                                </div>
                                <div className="fw-bold">{data.personal.branch}</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 ps-xl-2">
                                <div className="me-auto">
                                    Payroll ID :
                                </div>
                                <div className="fw-bold">{data.personal.payrollid}</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 ps-xl-2">
                                <div className="me-auto">
                                    VISA :
                                </div>
                                <div className="fw-bold">H1B - 30 June 2023</div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 ps-xl-2">
                                <div className="me-auto">
                                    Salary :
                                </div>
                                <div className="fw-bold">{data.personal.salary}</div>
                            </ListGroup.Item>
                        </ListGroup> */}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
export default Basicdetails;