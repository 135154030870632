import React from 'react';
import { Card } from 'react-bootstrap';

const Invoice = (props) => {
    const {data} = props
    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-3 header-title">Invoice</h5>
                <div className="d-flex mb-3">
                    <div className="ms-0">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Total Invoices Amount</h6>
                        <h4 className="mb-0 mt-1 pt-1 align-self-center fw-bold">$ {data[0]?.invoicedAmount?.toFixed(2)}</h4>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="ms-0">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Recieved Amount</h6>
                        <h4 className="mb-0 mt-1 pt-1 align-self-center fw-bold">$ {data[0]?.receivedAmount?.toFixed(2)}</h4>
                    </div>
                </div>
                <div className="totcirs mt-5">
                    <div className="d-flex">
                        <div className="ms-0">
                            <h6 className="fw-medium mt-0 mb-0">Pending Amount</h6>
                            <h4 className="mb-0 mt-1 pt-1 align-self-center fw-bold">$ {data[0]?.totalAmount?.toFixed(2)}</h4>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
export default Invoice;